import _ from 'lodash'
import React from 'react'
import classNames from 'classnames'
import apis from 'browser/app/models/apis'
import { Classes } from '@blueprintjs/core'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
import { Footer } from 'browser/components/atomic-elements/atoms/footer/footer'
import { ImageLoader } from 'browser/components/atomic-elements/atoms/image-loader/image-loader'
import { LandingPageEntity } from 'shared-libs/generated/server-types/entity/sms/landingPageEntity'
import { LoadingSpinner } from 'browser/components/atomic-elements/atoms/loading-spinner/loading-spinner'

interface ILandingProps {
  location: any
  history: any
  match: any
}

interface ILandingState {
  landingPage?: LandingPageEntity
}

/**
 * A landing page for the mobile-web app to help direct users to a check-in
 * experience, primarily a `tel:` link to SMS checkin and a mobile-web deeplink
 * to a specific check-in flow to serve as a fallback in case of Twilio issues.
 */
export class Landing extends React.Component<ILandingProps, ILandingState> {
  constructor(props: ILandingProps) {
    super(props)
    this.state = {}
  }

  public componentDidMount(): void {
    const { entityId } = this.props.match.params

    void apis.getLandingPage(entityId).then((landingPage) => {
      this.setState({ landingPage })
    })
  }

  public render(): JSX.Element {
    const { landingPage } = this.state
    if (!landingPage) {
      return <LoadingSpinner />
    }

    const { companyLogo, welcomeText, deeplinkUri } = landingPage.landingPage

    return (
      <div className="flex flex-column items-center center justify-center mw5">
        <div className="pt4 ph4 tc">
          <ImageLoader imageClassName="mw4" className="w-100 tc" src={companyLogo.files[0].uri} />
        </div>
        <h3 className="f3 b lh-title mt3 mb2 tc">
          {welcomeText}
        </h3>
        <div className="lh-copy mb3 mt4 tc">
          Tap Below to Check In
        </div>
        <Button
          className={classNames('mb4', Classes.INTENT_PRIMARY, Classes.FILL)}
          data-debug-id='startButton'
          tabIndex={1}
          size='large'
          onClick={this.handleStartSMSCheckin}
        >
          Start
        </Button>
        <Footer className="c-footer--center c-footer--transparent">
          <div className="tc pa3">
            If you don&#39;t get a response, click{' '}
            <a
              className="b"
              data-debug-id="deeplinkButton"
              href={deeplinkUri}
            >
              here
            </a>
          </div>
        </Footer>
      </div>
    )
  }

  private handleStartSMSCheckin = (): void => {
    const { landingPage } = this.state
    const { facilityPhoneNumber, smsMessage } = landingPage.landingPage
    const cleanedPhoneNumber = facilityPhoneNumber.phone.replace(/[^0-9]/g, '')
    const url = new URL(`sms:+${cleanedPhoneNumber}`)
    if (smsMessage) {
      url.searchParams.append('body', smsMessage)
    }
    const href = url.toString()
    window.location.href = href
  }
}
